import CustomShape from "assets/images/header/CustomShape.png";
import StockShape from "assets/images/header/StockShape.png";
import SamplePack from "assets/images/header/SamplePack.png";

import AboutUsIcon from "assets/svg/AboutUsIcon.svg";
import AffiliateIcon from "assets/svg/AffiliateIcon.svg";
import BlogIcon from "assets/svg/BlogIcon.svg";
import CategoriesIcon from "assets/svg/CategoriesIcon.svg";
import ContactIcon from "assets/svg/ContactIcon.svg";
import DesignTipsIcon from "assets/svg/DesignTipsIcon.svg";
import FaqIcon from "assets/svg/FaqIcon.svg";
import FragrancesIcon from "assets/svg/FragrancesIcon.svg";
import PricingIcon from "assets/svg/PricingIcon.svg";
// import PromotionsIcon from "assets/svg/PromotionsIcon.svg";
import ReviewsIcon from "assets/svg/ReviewsIcon.svg";
import ShippingProductionIcon from "assets/svg/ShippingProductionIcon.svg";
import StorePolicyIcon from "assets/svg/StorePolicyIcon.svg";

export const navigation = {
  shop: [
    { image: StockShape, name: "Stock Shape", href: "/stock-shapes" },
    {
      image: CustomShape,
      name: "Custom Shape",
      href: "/create/custom-details",
    },
    { image: SamplePack, name: "Sample Packs", href: "/samples" },
  ],
  learn: [
    { Icon: CategoriesIcon, name: "Categories", href: "/product-categories/" },
    { Icon: PricingIcon, name: "Pricing", href: "/shop" },
    { Icon: FragrancesIcon, name: "Fragrances", href: "/learn/fragrances" },
  ],
  support: [
    { Icon: ContactIcon, name: "Contact Us", href: "/contact", prefetch: false },
    { Icon: DesignTipsIcon, name: "Design Tips", href: "/help/how-it-works", prefetch: false },
    { Icon: FaqIcon, name: "FAQ", href: "/support/faq" },
    { Icon: StorePolicyIcon, name: "Store Policy", href: "/support/store-policies" },
    { Icon: ShippingProductionIcon, name: "Shipping & Production", href: "/support/shipping-production" },
  ],
  company: [
    {Icon: AboutUsIcon, name: "About Us", href: "/company/about-us/" },
    {Icon: ReviewsIcon, name: "Reviews", href: "/reviews" },
    // {Icon: AffiliateIcon, name: "Affiliate Program", href: "/company/affiliate-program" },
    {Icon: BlogIcon, name: "Blog", href: "/blog" },
  ],
};
