import api from "services/Api";

export const newsletterSubscribe = async (email) => {
  const resp = await api.post("v2/store/customers/subscribe/newsletter", {
    email,
  });

  return resp.data;
};

export const getCustomerDetail = async () => {
  const resp = await api.get("/v2/store/customers/detail");
  var _learnq = _learnq || [];
  _learnq.push(["identify", { $email: resp.email }]);
  return resp.data;
};

export const patchCustomerDetail = async (payload) => {
  const resp = await api.patch("v2/store/customers/detail", payload);

  return resp.data;
};

export const postCustomerAddress = async ({
  country,
  name,
  street1,
  street2,
  city,
  state,
  postal_code,
  residential,
  phone,
  tax_id,
  is_default,
}) => {
  const resp = await api.post("/v2/store/customers/location", {
    country,
    name,
    street1,
    city,
    state,
    postal_code,
    phone,
    ...(street2 && { street2 }),
    ...(residential && { residential }),
    ...(tax_id && { tax_id }),
    ...(is_default && { default: is_default }),
  });

  return resp.data;
};

export const putCustomerAddress = async (
  {
    country,
    name,
    street1,
    street2,
    city,
    state,
    postal_code,
    residential,
    phone,
    tax_id,
    is_default,
  },
  locationId
) => {
  const resp = await api.put(`/v2/store/customers/location/${locationId}`, {
    country,
    name,
    street1,
    city,
    state,
    postal_code,
    phone,
    default: is_default !== undefined ? is_default : false,
    residential: residential !== undefined ? residential : false,
    ...(street2 && { street2 }),
    ...(residential && { residential }),
    ...(tax_id && { tax_id }),
    ...(is_default && { default: is_default }),
  });

  return resp.data;
};

export const deleteCustomerAddress = async (locationId) => {
  const resp = await api.delete(`v2/store/customers/location/${locationId}`);

  return resp.data;
};

export const putCustomerPassword = async (payload) => {
  const resp = await api.put("v2/store/customers/update-password", payload);

  return resp.data;
};

export const unsubscribeEmail = async (token) => {
  const resp = await api.get(`v2/store/customers/unsubscribe?token=${token}`);

  return resp.data;
};
