import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { Fragment } from "react";

export const PopoverNavigation = ({
  title = "",
  items = [],
  className = "",
  children,
}) => {
  return (
    <Popover className={classNames("relative", className)}>
      {({ open }) => (
        <>
          <Popover.Button className="flex focus:outline-none text-gray-800 hover:text-gray-500">
            <span
              className={`text-xl font-semibold ${open ? "text-blue-500" : ""}`}
            >
              {title}
            </span>{" "}
            <ChevronDownIcon
              className={`h-5 w-5 ml-2 mt-1 ${
                open ? "rotate-180 text-blue-500" : ""
              }`}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 left-0 top-10 bg-white drop-shadow-[0_2px_5px_#0003] rounded-[20px] pt-2.5 pb-5 flex flex-col min-w-[320px]">
              {items.map(({ Icon, ...item }) => (
                <Link
                  key={item.name}
                  href={item.href}
                  {...(!item.prefetch && { prefetch: false })}
                >
                  <a className="cursor-pointer text-lg text-black hover:text-blue-500 rounded-[12px] transition-all duration-200 px-5 py-2.5 flex items-center justify-left space-x-4 focus:outline-none">
                    {item?.image && (
                      <Image src={item.image} width={70} height={70} />
                    )}
                    {Icon && (
                      <div className="bg-[#E9EFFD] rounded-lg flex items-center justify-center w-[62px] h-[62px]">
                        <Icon width={32} height={32} />
                      </div>
                    )}
                    <span>{item.name}</span>
                  </a>
                </Link>
              ))}
              {children}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
