import MmfLogo from "assets/svg/MmfLogo";
import Image from "next/image";
import Link from "next/link";
import { Tooltip } from "react-tooltip";
import { ArrowRight } from "lucide-react";
import PaintBrush from "assets/svg/PaintBrush.svg";
import RoundUSFlag from "assets/svg/RoundUSFlag.svg";
import FooterNavSection from "./FooterNavSection";
import { navigation, paymentMethods, socials } from "@/constants/footer";
import { newsletterSubscribe } from "@/services/CustomerService";
import { toast } from "react-toastify";
import { useCallback, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleNewsletterSubscription = useCallback(() => {
    setIsLoading(true);
    newsletterSubscribe(email)
      .then(() => {
        toast.info("Welcome! You've successfully joined our newsletter.");
        setEmail("");
      })
      .catch((e) => {
        toast.error(e?.message || "Sorry! Something went wrong.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [email]);

  return (
    <footer className="md:max-w-6xl md:mx-auto mt-6 w-full pb-12">
      <section className="px-6 sm:px-12 py-8 bg-blue-600 rounded-[40px] flex flex-col md:flex-row items-center justify-center sm:space-x-5 space-y-2 sm:space-y-0">
        <div className="flex items-start flex-col justify-center lg:mb-0 mb-2 sm:mb-5 mr-auto text-white">
          <h3 className="text-3xl font-semibold">Newsletter</h3>
          <h4 className="mt-2 sm:text-lg text-xl">
            Get special offers delivered straight
            <br className="sm:hidden" /> to your inbox!
          </h4>
        </div>
        <input
          className="default-input w-full sm:max-w-[30%] rounded-full px-4 py-2 text-lg"
          placeholder="Enter your email"
          type="email"
          readOnly={isLoading}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="white-button rounded-[16px] w-full sm:w-fit sm:text-base text-xl"
          onClick={handleNewsletterSubscription}
          disabled={isLoading || !email}
          type="button"
        >
          {isLoading && <LoadingSpinner className="text-gray-400" />}
          Submit <ArrowRight className="h-6 w-6 ml-3" />
        </button>
      </section>
      <div className="mx-auto blue-dashed-line-lg"></div>
      <section className="px-6 sm:px-12 py-8 bg-gray-100 rounded-[40px] flex flex-col md:flex-row items-center justify-center sm:space-x-5 space-y-5 sm:space-y-0">
        <div className="flex items-start sm:items-center justify-center lg:mb-0 sm:mb-5 mr-auto">
          <MmfLogo width={75} height={50} className="block sm:hidden" />
          <MmfLogo width={150} height={100} className="hidden sm:block" />
        </div>
        <h3 className="text-2xl font-semibold">Custom Rides. Fresh Vibes.</h3>
        <Link href="/shop">
          <a className="blue-button w-full sm:w-fit sm:text-base text-xl">
            <PaintBrush
              height={20}
              width={20}
              className="mr-2 sm:mt-0.5 mt-1.5"
            />
            Design Now
          </a>
        </Link>
      </section>
      <div className="mx-auto dashed-line-lg"></div>
      <section className="px-12 py-8 max-w-6xl mx-auto bg-gray-100 rounded-[40px] ">
        <nav className="max-w-6xl mx-auto flex flex-col sm:flex-row items-start justify-between space-y-5 sm:space-y-0">
          <FooterNavSection title="Shop" items={navigation.shop} />
          <FooterNavSection title="Learn" items={navigation.learn} />
          <FooterNavSection title="Support" items={navigation.support} />
          <FooterNavSection title="Company" items={navigation.company} />

          <section>
            <h4 className="text-lg font-semibold mb-4">We accept:</h4>
            <ul className="grid grid-cols-4  gap-y-1 gap-x-2">
              {paymentMethods.slice(0, 4).map((paymentMethod) => (
                <li key={paymentMethod.id} id={paymentMethod.id}>
                  <Image
                    src={paymentMethod.image}
                    alt={paymentMethod.label}
                    height={25}
                    width={40}
                  />
                  <Tooltip anchorSelect={`#${paymentMethod.id}`}>
                    {paymentMethod.label}
                  </Tooltip>
                </li>
              ))}
            </ul>
            <ul className="grid grid-cols-4  gap-y-1 gap-x-2">
              {paymentMethods.slice(4).map((paymentMethod) => (
                <li key={paymentMethod.id} id={paymentMethod.id}>
                  <Image
                    src={paymentMethod.image}
                    alt={paymentMethod.label}
                    height={25}
                    width={40}
                  />
                  <Tooltip anchorSelect={`#${paymentMethod.id}`}>
                    {paymentMethod.label}
                  </Tooltip>
                </li>
              ))}
            </ul>
          </section>
        </nav>
        <section className="mt-8 max-w-6xl mx-auto flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0">
          <div className="text-base font-medium text-gray-500 flex flex-col sm:flex-row space-y-2 sm:space-y-0">
            <p className="sm:mr-3 leading-[25px]">
              &copy; {new Date().getFullYear()} Make My Freshener.{" "}
              <br className="sm:hidden" />
              All Rights Reserved.
            </p>
            <p className="flex flex-row">
              <RoundUSFlag
                width={23}
                height={23}
                alt="made in USA"
                className=""
              />

              <p className="ml-3 mt-0.5 lg:mt-0 leading-[25px]">
                Made in the USA | $(USD)
              </p>
            </p>
          </div>
          <ul className="flex space-x-6 -mb-2">
            {socials.map(({ Icon, ...social }) => (
              <li
                key={social.id}
                id={social.id}
                className="hover:-translate-y-0.5 transition ease-in duration-200"
              >
                <a href={social.href} target="_blank">
                  <Icon alt={social.label} height={24} width={24} />
                  <Tooltip anchorSelect={`#${social.id}`}>
                    {social.label}
                  </Tooltip>
                </a>
              </li>
            ))}
          </ul>
        </section>
      </section>
    </footer>
  );
};

export default Footer;
