import Footer from "components/common/Footer";
import { Header } from "./Header";

const Layout = ({ children, hideHeader }) => {
  return (
    <>
      <div className={hideHeader ? "hidden" : ""}>
        <Header />
      </div>
      <main
        className={`flex-1 h-screen ${
          hideHeader ? "pt-0" : "pt-[74px]"
        }`}
      >
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;
